<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M7.99937 0L0 5.98473L7.99937 11.9696L16 5.98459L7.99937 0Z"
      fill="#0061FF"
    />
    <path d="M24 0L16 5.98532L24 11.9706L32 5.98532L24 0Z" fill="#0061FF" />
    <path
      d="M0 17.9554L7.99937 23.9403L16 17.9553L7.99937 11.9707L0 17.9554Z"
      fill="#0061FF"
    />
    <path
      d="M24 11.9707L16 17.956L24 23.9412L32 17.956L24 11.9707Z"
      fill="#0061FF"
    />
    <path
      d="M8 25.9476L16.0006 31.9325L24 25.9475L16.0006 19.9629L8 25.9476Z"
      fill="#0061FF"
    />
  </svg>
</template>

<script>
export default {
  name: "DropBox",
};
</script>
